<template>
  <div class="activity-tab">
    <h3 class="primary--text mb-4">{{ $t("wallet.latestTransactions") }}</h3>

    <div
      v-for="(activity, i) in activities"
      :key="i"
      class="d-flex align-center mb-5"
    >
      <div class="pos-rel mr-3">
        <v-avatar size="60">
          <v-img
            :src="$utils.placeholderImage('60x60', 'PA', '0B2540')"
            width="60"
            height="60"
            alt="Image"
          />
        </v-avatar>

        <v-icon
          :color="!activity.is_sender ? 'primary' : '#0F5EA2'"
          class="pos-abs"
          style="bottom: -2px; right: -3px"
        >
          {{
            !activity.is_sender
              ? "mdi-arrow-left-circle"
              : "mdi-arrow-right-circle"
          }}
        </v-icon>
      </div>

      <div>
        <div>
          {{
            activity.is_sender
              ? $t("wallet.transferTo", { s: shortenAddress(activity.to) })
              : $t("wallet.transferFrom", { s: shortenAddress(activity.from) })
          }}
        </div>

        <time
          class="font-size-14 text--secondary"
          :datetime="activity.created_at"
        >
          {{
            $utils.dayjs(activity.created_at).format("MMMM D, YYYY HH:mm:ss")
          }}
        </time>
      </div>

      <v-spacer />

      <div class="font-size-18 font-weight-medium d-flex">
        {{ activity.is_sender ? "-" : "+"
        }}{{ Number(activity.value).toFixed(2) }}

        <v-img
          :src="require('@/assets/images/cfoot-circle.png')"
          alt="C-Foot logo"
          width="27"
          class="ml-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    activities: [
      {
        transactionHash:
          "0xe6e7340394958674cdf8606936d292f565e4ecc476aaa8b258ec8a141f7c75d7",
        from: "0x81b7E08F65Bdf5648606c89998A9CC8164397647",
        to: "0xbC546fa1716Ed886967cf73f40e8F2F5e623a92d",
        value: "12",
        is_sender: Math.random() < 0.5,
      },
      {
        transactionHash:
          "0xe6e7340394958674cdf8606936d292f565e4ecc476aaa8b258ec8a141f7c75d7",
        from: "0x81b7E08F65Bdf5648606c89998A9CC8164397647",
        to: "0xbC546fa1716Ed886967cf73f40e8F2F5e623a92d",
        value: "23",
        is_sender: Math.random() < 0.5,
      },
      {
        transactionHash:
          "0xe6e7340394958674cdf8606936d292f565e4ecc476aaa8b258ec8a141f7c75d7",
        from: "0x81b7E08F65Bdf5648606c89998A9CC8164397647",
        to: "0xbC546fa1716Ed886967cf73f40e8F2F5e623a92d",
        value: "5",
        is_sender: Math.random() < 0.5,
      },
      {
        transactionHash:
          "0xe6e7340394958674cdf8606936d292f565e4ecc476aaa8b258ec8a141f7c75d7",
        from: "0x81b7E08F65Bdf5648606c89998A9CC8164397647",
        to: "0xbC546fa1716Ed886967cf73f40e8F2F5e623a92d",
        value: "31",
        is_sender: Math.random() < 0.5,
      },
      {
        transactionHash:
          "0xe6e7340394958674cdf8606936d292f565e4ecc476aaa8b258ec8a141f7c75d7",
        from: "0x81b7E08F65Bdf5648606c89998A9CC8164397647",
        to: "0xbC546fa1716Ed886967cf73f40e8F2F5e623a92d",
        value: "1",
        is_sender: Math.random() < 0.5,
      },
    ],
  }),

  created() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.$store.dispatch("wallet/activity").then((activities) => {
        if (activities.length) {
          this.activities = activities;
        }
      });
    },

    shortenAddress(address) {
      return address.slice(0, 4) + "..." + address.slice(-4);
    },
  },
};
</script>

<style></style>
